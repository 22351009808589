import React from 'react';
import { UniqueIdentifier, useDroppable } from '@dnd-kit/core';
import styled from 'styled-components';

import { Palette } from 'core';

type TrashProps = {
  id: UniqueIdentifier;
};

export const Trash: React.FunctionComponent<TrashProps> = ({ id }) => {
  const { setNodeRef, isOver } = useDroppable({
    id,
  });

  return (
    <TrashBasket $isOver={isOver} ref={setNodeRef}>
      x Drop here to delete track x
    </TrashBasket>
  );
};

const TrashBasket = styled.div<{ $isOver: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 350px;
  height: 35px;
  border: 2px dashed;
  border-radius: 5px;
  border-color: ${({ $isOver }) => ($isOver ? Palette.RED : Palette.WHITE)};
  color: ${Palette.WHITE};
`;
