import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route as ReactRoute } from 'react-router-dom';

import Route, { RouteProps, RouteAccess } from './Route';
import { LoadingSpinner } from 'view/components/common';
import { MainLayout } from 'view/components/layout';

const PrivacyPage = lazy(
  () =>
    import(
      /* webpackChunkName: 'PrivacyPage' */ /* webpackPrefetch: true */ 'view/pages/Legal/Policies/PrivacyPolicy'
    )
);
const TermsPage = lazy(
  () =>
    import(
      /* webpackChunkName: 'TermsOfServicePage' */ /* webpackPrefetch: true */ 'view/pages/Legal/Policies/TermsOfService'
    )
);
const CookiesPage = lazy(
  () =>
    import(
      /* webpackChunkName: 'CookiesPage' */ /* webpackPrefetch: true */ 'view/pages/Legal/Policies/CookiePolicy'
    )
);
const RefundsPage = lazy(
  () =>
    import(
      /* webpackChunkName: 'RefundsPage' */ /* webpackPrefetch: true */ 'view/pages/Legal/Policies/RefundPolicy'
    )
);
const AccountPage = lazy(
  () =>
    import(/* webpackChunkName: 'AccountPage' */ /* webpackPrefetch: true */ 'view/pages/Account')
);
const FAQPage = lazy(
  () => import(/* webpackChunkName: 'FAQPage' */ /* webpackPrefetch: true */ 'view/pages/FAQ')
);
const PricingPage = lazy(
  () =>
    import(/* webpackChunkName: 'PricingPage' */ /* webpackPrefetch: true */ 'view/pages/Pricing')
);
const NetworkPage = lazy(
  () =>
    import(/* webpackChunkName: 'NetworkPage' */ /* webpackPrefetch: true */ 'view/pages/Network')
);
const SupportPage = lazy(
  () =>
    import(/* webpackChunkName: 'SupportPage' */ /* webpackPrefetch: true */ 'view/pages/Support')
);
const HomePage = lazy(
  () => import(/* webpackChunkName: 'HomePage' */ /* webpackPrefetch: true */ 'view/pages/Home')
);
const NotFoundPage = lazy(
  () =>
    import(/* webpackChunkName: 'NotFoundPage' */ /* webpackPrefetch: true */ 'view/pages/NotFound')
);
const ConfirmationPage = lazy(
  () =>
    import(
      /* webpackChunkName: 'ConfirmationPage' */ /* webpackPrefetch: true */ 'view/pages/Confirmation'
    )
);
const MixerPage = lazy(
  () => import(/* webpackChunkName: 'MixerPage' */ /* webpackPrefetch: true */ 'view/pages/Mixer')
);
const LegalPage = lazy(
  () => import(/* webpackChunkName: 'LegalPage' */ /* webpackPrefetch: true */ 'view/pages/Legal')
);
const AboutPage = lazy(
  () => import(/* webpackChunkName: 'AboutPage' */ /* webpackPrefetch: true */ 'view/pages/About')
);

export enum RoutePath {
  PRIVACY_POLICY = '/legal/privacy-policy',
  COOKIE_POLICY = '/legal/cookie-policy',
  REFUND_POLICY = '/legal/refund-policy',
  NOT_FOUND = '/legal/page-not-found',
  TERMS = '/legal/terms-of-service',
  CONFIRMATION = '/confirmation',
  FAQ = '/faq',
  PRICING = '/pricing',
  ACCOUNT = '/account',
  SUPPORT = '/support',
  NETWORK = '/network',
  SUCCESS = '/success',
  LEGAL = '/legal',
  MIXER = '/mixer',
  ABOUT = '/about',
  ROOT = '/',
}

const routes: Array<RouteProps> = [
  {
    path: RoutePath.ROOT,
    access: RouteAccess.public,
    layout: MainLayout,
    component: HomePage,
  },
  {
    path: RoutePath.ACCOUNT,
    access: RouteAccess.private,
    layout: MainLayout,
    component: AccountPage,
  },
  {
    path: RoutePath.LEGAL,
    access: RouteAccess.public,
    layout: MainLayout,
    component: LegalPage,
  },
  {
    path: RoutePath.TERMS,
    access: RouteAccess.public,
    layout: MainLayout,
    component: TermsPage,
  },
  {
    path: RoutePath.PRIVACY_POLICY,
    access: RouteAccess.public,
    layout: MainLayout,
    component: PrivacyPage,
  },
  {
    path: RoutePath.COOKIE_POLICY,
    access: RouteAccess.public,
    layout: MainLayout,
    component: CookiesPage,
  },
  {
    path: RoutePath.REFUND_POLICY,
    access: RouteAccess.public,
    layout: MainLayout,
    component: RefundsPage,
  },
  {
    path: RoutePath.SUPPORT,
    access: RouteAccess.public,
    layout: MainLayout,
    component: SupportPage,
  },
  {
    path: RoutePath.NETWORK,
    access: RouteAccess.public,
    layout: MainLayout,
    component: NetworkPage,
  },
  {
    path: RoutePath.PRICING,
    access: RouteAccess.public,
    layout: MainLayout,
    component: PricingPage,
  },
  {
    path: RoutePath.FAQ,
    access: RouteAccess.public,
    layout: MainLayout,
    component: FAQPage,
  },
  {
    path: RoutePath.CONFIRMATION,
    access: RouteAccess.public,
    layout: MainLayout,
    component: ConfirmationPage,
  },
  {
    path: RoutePath.MIXER,
    access: RouteAccess.public,
    layout: MainLayout,
    component: MixerPage,
  },
  {
    path: RoutePath.ABOUT,
    access: RouteAccess.public,
    layout: MainLayout,
    component: AboutPage,
  },
];

const AppRouter: React.FunctionComponent = () => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <BrowserRouter>
        <Routes>
          {routes.map(({ path, access, component, ...props }: RouteProps) => {
            return (
              <ReactRoute
                // index
                key={path}
                path={path}
                element={<Route path={path} access={access} component={component} {...props} />}
              />
            );
          })}
          <ReactRoute path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default AppRouter;
