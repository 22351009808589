import { createClient } from '@supabase/supabase-js';
import { Database } from '../types_db';

const supabaseUrl =
  process.env.REACT_APP_SUPABASE_URL ?? process.env.REACT_APP_SUPABASE_DEV_URL ?? '';

const supabaseKey =
  process.env.REACT_APP_SUPABASE_ANON_KEY ?? process.env.REACT_APP_SUPABASE_ANON_LOCAL_KEY ?? '';

export const supabase = createClient<Database>(supabaseUrl, supabaseKey);

export async function getSession() {
  try {
    const {
      data: { session },
    } = await supabase.auth.getSession();
    return session;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getUserDetails() {
  try {
    const { data: userDetails } = await supabase.from('users').select('*').single();
    return userDetails;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getSubscription() {
  try {
    const { data: subscription } = await supabase
      .from('subscriptions')
      .select('*, prices(*, products(*))')
      .in('status', ['active'])
      .maybeSingle()
      .throwOnError();
    return subscription;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const getActiveProductsWithPrices = async () => {
  const { data, error } = await supabase
    .from('products')
    .select('*, prices(*)')
    .eq('active', true)
    .eq('prices.active', true)
    .order('metadata->index')
    .order('unit_amount', { foreignTable: 'prices' });

  if (error) {
    console.error(error.message);
  }

  return data ?? [];
};
