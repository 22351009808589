import React, { useEffect } from 'react';
import { Navigate, RouteProps } from 'react-router-dom';

type PublicRouteProps = {
  path: string;
  isAuth?: boolean;
  isRestricted?: boolean;
  layout?: React.ComponentType<RouteProps>;
  component?: React.ComponentType<RouteProps>;
  privateComponent?: React.ComponentType<RouteProps>;
} & RouteProps;

export const PublicRoute: React.FunctionComponent<PublicRouteProps> = ({
  path,
  isAuth,
  isRestricted,
  layout: Layout,
  component: Component = () => null,
  privateComponent: PrivateComponent,
  ...rest
}) => {
  useEffect(() => {
    if (path.includes('legal')) return;
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [path]);

  return isAuth && !isRestricted ? (
    PrivateComponent === undefined ? (
      <Navigate to="/" replace />
    ) : Layout === undefined ? (
      <PrivateComponent {...rest} />
    ) : (
      <Layout>
        <PrivateComponent {...rest} />
      </Layout>
    )
  ) : Layout === undefined ? (
    <Component {...rest} />
  ) : (
    <Layout>
      <Component {...rest} />
    </Layout>
  );
};
