import { createContext } from 'react';
import { ToastMessageType } from './Toast';

interface ToastContextInterface {
  showToast: (message: string, type: ToastMessageType) => void;
  removeToast: (id: string) => void
};

export const ToastContext = createContext<ToastContextInterface>({
  showToast: () => {},
  removeToast: () => {}
});