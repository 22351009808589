import { css, keyframes } from 'styled-components';

export const NoSelect = css`
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select  : none; /* Safari */
    -khtml-user-select  : none; /* Konqueror HTML */
      -moz-user-select  : none; /* Old versions of Firefox */
      -ms-user-select  : none; /* Internet Explorer/Edge */
          user-select  : none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
`;

export const FadeOut = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`;

export const FadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;