import React, { useEffect } from 'react';
import type { DraggableSyntheticListeners } from '@dnd-kit/core';
import styled, { keyframes, css } from 'styled-components';
import type { Transform } from '@dnd-kit/utilities';

import { MediaQuery, Palette, SpotifyTrackData, useAuthStore } from 'core';
import { useToast } from 'view/hooks';
import { Handle, Remove } from '.';

export interface ItemProps {
  $dragOverlay?: boolean;
  color?: string;
  $disabled?: boolean;
  $dragging?: boolean;
  $handle?: boolean;
  handleProps?: any;
  height?: number;
  index?: number;
  $fadeIn?: boolean;
  transform?: Transform | null;
  listeners?: DraggableSyntheticListeners;
  $sorting?: boolean;
  style?: React.CSSProperties;
  transition?: string | null;
  wrapperStyle?: React.CSSProperties;
  value: SpotifyTrackData | null;
  containerId?: string | number;
  onRemove?(): void;
  renderItem?(args: {
    $dragOverlay: boolean;
    $dragging: boolean;
    $sorting: boolean;
    index: number | undefined;
    $fadeIn: boolean;
    listeners: DraggableSyntheticListeners;
    ref: React.Ref<HTMLElement>;
    style: React.CSSProperties | undefined;
    transform: ItemProps['transform'];
    transition: ItemProps['transition'];
    value: ItemProps['value'];
  }): React.ReactElement;
}

export const Item = React.memo(
  React.forwardRef<HTMLLIElement, ItemProps>(
    (
      {
        color,
        $dragOverlay,
        $dragging,
        $disabled,
        $fadeIn,
        $handle,
        handleProps,
        containerId,
        height,
        index,
        listeners,
        onRemove,
        renderItem,
        $sorting,
        style,
        transition,
        transform,
        value: track,
        wrapperStyle,
        ...props
      },
      ref
    ) => {
      const { showToast } = useToast();

      const [currentTrack, addCurrentTrack, addCurrentTrackHovered, spotifyUserProfile] =
        useAuthStore((state) => [
          state.currentTrack,
          state.addCurrentTrack,
          state.addCurrentTrackHovered,
          state.spotifyUserProfile,
        ]);

      useEffect(() => {
        if (!$dragOverlay) {
          return;
        }

        document.body.style.cursor = 'grabbing';

        return () => {
          document.body.style.cursor = '';
        };
      }, [$dragOverlay]);

      return renderItem ? (
        renderItem({
          $dragOverlay: Boolean($dragOverlay),
          $dragging: Boolean($dragging),
          $sorting: Boolean($sorting),
          $fadeIn: Boolean($fadeIn),
          transition,
          transform,
          listeners,
          style,
          index,
          value: track,
          ref,
        })
      ) : (
        <Wrapper
          ref={ref}
          $fadeIn={$fadeIn}
          $sorting={$sorting}
          $dragOverlay={$dragOverlay}
          onMouseLeave={() => addCurrentTrackHovered(currentTrack ?? track)}
          onMouseEnter={() => addCurrentTrackHovered(track)}
          onClick={() => {
            if (spotifyUserProfile?.explicit_content.filter_enabled && track?.explicit === 'yes')
              return showToast(
                'Your explicit content filter is enabled. Enable explicit content in your Spotify settings to play this track.',
                'failure'
              );
            addCurrentTrack(track);
          }}
          transform={transform}
          // index={index}
          color={color}
          style={
            {
              ...wrapperStyle,
              transition: [transition, wrapperStyle?.transition].filter(Boolean).join(', '),
              '--translate-x': transform ? `${Math.round(transform.x)}px` : undefined,
              '--translate-y': transform ? `${Math.round(transform.y)}px` : undefined,
              '--scale-x': transform?.scaleX ? `${transform.scaleX}` : undefined,
              '--scale-y': transform?.scaleY ? `${transform.scaleY}` : undefined,
              '--index': index,
              '--color': color,
            } as React.CSSProperties
          }
        >
          <WrapperItem
            tabIndex={!$handle ? 0 : undefined}
            data-cypress="draggable-item"
            $dragOverlay={$dragOverlay}
            $dragging={$dragging}
            $disabled={$disabled}
            onRemove={onRemove}
            $handle={$handle}
            color={color}
            style={style}
            $camelotteColor={track?.camelotteColor}
            {...(!$handle ? listeners : undefined)}
            {...props}
          >
            {containerId === 'A' ? (
              <React.Fragment>
                <TableData $order>
                  {/* {(index ?? 0) + 1} / {track?.order} */}
                  {(index ?? 0) + 1}
                </TableData>
                <TableData $start>{track?.name}</TableData>
                <TableData $start>{track?.artist}</TableData>
                <TableData>B: {track?.tempo}</TableData>
                <TableData>K: {track?.camelotteKey}</TableData>
                <TableData>E: {track?.energy}%</TableData>
                <TableData>M: {track?.valence}%</TableData>
                {/* <TableData>{track?.daysSinceAdded}</TableData> */}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <TableData $start>{track?.name}</TableData>
                <TableData>
                  {track?.tempo} / {track?.camelotteKey}
                </TableData>
              </React.Fragment>
            )}
            <Actions>
              {onRemove ? (
                <Remove className="Remove" style={{ visibility: 'hidden' }} onClick={onRemove} />
              ) : null}
              {$handle ? <Handle {...handleProps} {...listeners} /> : null}
            </Actions>
          </WrapperItem>
        </Wrapper>
      );
    }
  )
);

const TableData = styled.div<{ $order?: boolean; $start?: boolean }>`
  text-align: ${({ $start }) => ($start ? 'start' : 'center')};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  min-width: 50px;
  max-width: ${({ $order }) => ($order ? '70' : '160')}px;
  padding-right: 10px;
  background-color: inherit;
  list-style: none;
  color: #000;
  font-weight: bold;
  font-size: 1rem;
  @media ${MediaQuery.laptopL} {
    min-width: ${({ $order }) => ($order ? '50' : '70')}px;
  }
`;

const BoxShadow = (transform: ItemProps['transform']) =>
  css`0 0 0 calc(1px /${transform?.scaleX ?? 1}) rgba(63, 63, 68, 0.05), 0 1px calc(3px / ${
    transform?.scaleX ?? 1
  }) 0 rgba(34, 33, 81, 0.15)`;

const pop = (transform: ItemProps['transform']) => keyframes`
  0% {
    transform: scale(1);
    box-shadow: ${BoxShadow(transform)};
  }
  100% {
    transform: scale(1.05);
    box-shadow: 0 0 0 calc(1px / ${transform?.scaleX ?? 1}) rgba(63, 63, 68, 0.05), 
      -1px 0 15px 0 rgba(34, 33, 81, 0.01),
      0px 15px 15px 0 rgba(34, 33, 81, 0.25);
  }
`;

const fadeInKeyframe = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Wrapper = styled.li<Omit<ItemProps, 'value' | 'index'>>`
  display: flex;

  transform: ${({ transform }) =>
    transform
      ? css`
        translate3d(${Math.round(transform?.x ?? 0)}px, ${Math.round(transform?.y ?? 0)}px, 0)
        scaleX(${Math.round(transform?.scaleX ?? 1)}) 
        scaleY(${Math.round(transform?.scaleY ?? 1)})
      `
      : css`
        translate3d(0, 0, 0)
        scaleX(1) 
        scaleY(1)
      `};

  transform-origin: 0 0;
  touch-action: manipulation;
  cursor: pointer;

  ${({ $fadeIn }) =>
    $fadeIn
      ? css`
          animation: ${fadeInKeyframe} 500ms ease;
        `
      : ''};

  ${({ $dragOverlay }) => ($dragOverlay ? 'z-index: 999' : '')};
`;

type WrapperItemProps = Omit<ItemProps, 'value' | 'index'> & {
  $camelotteColor: string | undefined;
};

const WrapperItem = styled.div<WrapperItemProps>`
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding: 15px;
  background-color: ${({ $camelotteColor }) => $camelotteColor ?? Palette.WHITE};
  box-shadow: ${({ transform }) => BoxShadow(transform)};
  border-radius: calc(4px / ${({ transform }) => transform?.scaleX ?? 1});
  border: 2px solid ${Palette.BLACK};
  outline: none;
  list-style: none;
  transform-origin: 50% 50%;

  -webkit-tap-highlight-color: transparent;

  color: #333;
  font-weight: 400;
  font-size: 1rem;
  white-space: nowrap;

  transform: scale(${({ transform }) => transform?.scaleX ?? 1});
  transition: box-shadow 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);

  &:focus-visible {
    box-shadow: 0 0px 4px 1px #4c9ffe, ${({ transform }) => BoxShadow(transform)};
  }

  ${({ $handle }) =>
    !$handle
      ? css`
          touch-action: manipulation;
          cursor: grab;
        `
      : ''};

  ${({ $dragging, $dragOverlay, transform }) =>
    $dragging && !$dragOverlay
      ? css`
          opacity: 0.5;
          z-index: 0;

          &:focus {
            box-shadow: ${BoxShadow(transform)};
          }
        `
      : ''};

  ${({ $disabled, transform }) =>
    $disabled
      ? css`
          color: #999;
          background-color: #f1f1f1;
          cursor: not-allowed;

          &:focus {
            box-shadow: 0 0px 4px 1px rgba(0, 0, 0, 0.1), ${BoxShadow(transform)};
          }
        `
      : ''};

  ${({ $dragOverlay, transform }) =>
    $dragOverlay
      ? css`
          cursor: inherit;
          animation: ${pop(transform)} 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
          transform: scale(${transform?.scaleX ?? 1});
          box-shadow: 0 0 0 calc(1px / ${transform?.scaleX ?? 1}) rgba(63, 63, 68, 0.05),
            -1px 0 15px 0 rgba(34, 33, 81, 0.01), 0px 15px 15px 0 rgba(34, 33, 81, 0.25);
          opacity: 1;
        `
      : ''};

  ${({ color }) =>
    color
      ? css`
          &:before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            height: 100%;
            width: 3px;
            display: block;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            background-color: ${color};
          }
        `
      : ''};

  &:hover {
    border: 2px solid ${Palette.WHITE};
  }

  &:hover {
    .Remove {
      visibility: visible;
    }
    .Add {
      visibility: visible;
    }
  }
`;

const Actions = styled.span`
  display: flex;
  align-self: flex-start;
  margin-top: -12px;
  margin-left: auto;
  margin-bottom: -15px;
  margin-right: -10px;
`;
