import React from 'react';
import { Navigate, RouteProps } from 'react-router-dom';

type PrivateRouteProps = {
    isAuth?          : boolean;
    layout?          : React.ComponentType<RouteProps>;
    component?       : React.ComponentType<RouteProps>;
    publicComponent? : React.ComponentType<RouteProps>;
} & RouteProps

export const PrivateRoute : React.FunctionComponent<PrivateRouteProps> = ({ 
    isAuth, 
    layout : Layout, 
    component : Component = () => null, 
    publicComponent : PublicComponent,
    ...rest 
}) => {
  return isAuth 
    ? (
      Layout === undefined ? (
        <Component {...rest} />
      ) : (
        <Layout>
          <Component {...rest} />
        </Layout>
      )
    ) : (
      PublicComponent === undefined ? (
        <Navigate to="/" replace />
      ) : (
        Layout === undefined ? (
          <PublicComponent {...rest} />
        ) : (
          <Layout>
              <PublicComponent {...rest} />
          </Layout>
        )
      )
    );
};