import { useEffect, useState } from 'react';
import { isEmpty } from 'core/utilities';

interface FormProps<T extends { [key: string]: any }> {
  initialValues: T;
  onSubmit: (values: T, alteredFields: (keyof T)[]) => {};
  validate?: (values: T) => { [key: string]: string };
  onResponseFieldError?: (response: {}) => T;
}

export function useForm<T extends { [key: string]: any }>({
  initialValues,
  validate,
  onSubmit,
  onResponseFieldError,
}: FormProps<T>) {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState<{ [key: string]: string }>(
    validate?.(values) || {}
  );
  const [touched, setTouched] = useState<{ [key: string]: boolean }>({});
  const [onSubmitting, setOnSubmitting] = useState<boolean>(false);

  const isValid = !isEmpty(touched) && isEmpty(errors);

  const alteredFields = Object.entries(values).reduce(
    (acc: (keyof T)[], [key, value]) => {
      if (
        initialValues[key] instanceof Date &&
        value instanceof Date &&
        initialValues[key].valueOf() === value.valueOf()
      ) {
        return acc;
      }
      if (initialValues[key] !== value) {
        acc = [...acc, key];
      }
      return acc;
    },
    []
  );

  const changeValue = (name: keyof T) => (value: any) => {
    setValues((prev) => ({ ...prev, [name]: value }));
    setTouched((prevTouched) => ({ ...prevTouched, [name]: true }));
  };

  const resetValues = () => {
    setValues(initialValues);
    setTouched({});
    setErrors({});
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | React.KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement | HTMLSelectElement | any;
    const { type, name } = target;

    const getValue = () => {
      if (type === 'checkbox' && e.type !== 'keypress' && !target.checked) {
        return "";
      }
      if (e.type === 'keypress' && type === 'checkbox' && target.checked) {
        return "";
      }
      return target.value;
    };

    const value = getValue();

    setValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name } = e.target;
    setTouched((prevTouched) => ({ ...prevTouched, [name]: true }));
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setOnSubmitting(true);
    if (e && typeof e.preventDefault === 'function') {
      e.preventDefault();
    }
    if (isValid) {
      const response: {} = await onSubmit(values, alteredFields);
      setOnSubmitting(false);

      if (onResponseFieldError) {
        setErrors((prev) => ({ ...prev, ...onResponseFieldError(response) }));
      }
    }
  };

  useEffect(() => {
    if (validate) {
      setErrors(validate(values));
    }
  }, [values, setErrors, validate]);

  return {
    handleBlur,
    handleChange,
    handleSubmit,
    alteredFields,
    onSubmitting,
    isValid,
    values,
    errors,
    touched,
    changeValue,
    resetValues,
  };
}
