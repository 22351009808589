import React, { ErrorInfo, ReactNode } from 'react';

interface Props {
  children?: ReactNode;
  fallback?: String;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
    // Add aws clouwatch RUM logging
  }

  public render() {
    if (this.state.hasError) {
      return <h1>{this.props.fallback ? this.props.fallback : 'Sorry.. there was an error'}</h1>;
    }

    return this.props.children;
  }
}
