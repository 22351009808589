
export enum Environment {
  DEVELOPMENT = 'development',
  PRODUCTION  = 'production'
};

export enum HTTPMethod {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
  HEAD = 'HEAD',
};

export enum Route {
  REFRESH_SPOTIFY = 'refresh-spotify-token',
  CREATE_CHECKOUT = 'create-checkout-session',
  CREATE_PORTAL = 'create-portal-session',
  NEWSLETTER = 'newsletter',
  USER = 'user'
};

export enum SPOTIFY_ENDPOINT {
  ME = 'me',
  PLAYLISTS = 'playlists',
  TRACKS = 'tracks'
};

export const NODE_ENV = process.env.REACT_APP_NODE_ENV;
export const IS_DEVELOPMENT = Environment.DEVELOPMENT === NODE_ENV;
export const IS_PRODUCTION  = Environment.PRODUCTION  === NODE_ENV;
export const CLIENT_URL = IS_PRODUCTION ? 'https://spmixer.com' : 'http://localhost:3000';

export const BASE_URL = (() => 
{
    const URL = process.env.REACT_APP_BASE_URL;
    if (URL) return URL;
    throw new Error('REACT_APP_BASE_URL is undefined, aborting...');
})();

export const DISCORD_LINK = 'https://discord.gg/DRcx7sE2kM';
export const SPOTIFY_URI = 'https://api.spotify.com/v1';
export const CDN_BASE_URI = 'https://cdn.spmixer.com';
export const FETCH_TIME_DURATION = 8000; //8 seconds
export const SPOTIFY_SCOPE = `
  streaming 
  user-read-email 
  ugc-image-upload
  user-read-private 
  playlist-read-private 
  playlist-modify-public 
  playlist-modify-private 
  user-modify-playback-state
  playlist-read-collaborative
`;
