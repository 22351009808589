import React from 'react';
import { useAuthStore } from 'core/store';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';

export enum RouteAccess {
  public = 'public',
  private = 'private',
}

export interface RouteProps {
  exact?: boolean;
  path: string;
  access: RouteAccess;
  component?: React.ComponentType;
  privateComponent?: React.ComponentType;
  publicComponent?: React.ComponentType;
  layout?: React.ComponentType;
}

const Route: React.FunctionComponent<RouteProps> = ({ access, component, ...props }) => {
  const session = useAuthStore((state) => state.session);

  const isAuth = session ? Boolean(session?.user?.email) : false;

  switch (access) {
    case RouteAccess.public:
      return <PublicRoute component={component} {...props} isAuth={false} />;
    case RouteAccess.private:
      return <PrivateRoute component={component} {...props} isAuth={isAuth} />;
  }
};

export default React.memo(Route);
