import React from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import styled from 'styled-components';

import { average } from 'core/utilities';
import { SpotifyTrackData } from 'core';

const Chart: React.FunctionComponent<{ data: Array<SpotifyTrackData> }> = ({ data = [] }) => {
  const averages = [
    {
      subject: 'Valence',
      A: average(data.map((item) => item.valence)),
      // fullMark: 100,
    },
    {
      subject: 'Energy',
      A: average(data.map((item) => item.energy)),
      // fullMark: 100
    },
    {
      subject: 'BPM',
      A: average(data.map((item) => item.tempo)),
      // fullMark: 200
    },
    {
      subject: 'Key',
      A: average(data.map((item) => item.key)),
      // fullMark: 12
    },
  ];

  return (
    <React.Fragment>
      <LineChartContainer>
        {data.length ? (
          <LineChart
            width={900}
            height={400}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            <XAxis dataKey="Name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="key" stroke="#176846" />
            <Line type="monotone" dataKey="BPM" stroke="#8884d8" activeDot={{ r: 8 }} />
            <Line type="monotone" dataKey="energy" stroke="#82ca9d" />
            <Line type="monotone" dataKey="valence" stroke="#b11d9e" />
          </LineChart>
        ) : (
          'No Chart Data'
        )}
      </LineChartContainer>

      {/* <RadarChart 
          cx="50%" 
          cy="50%" 
          outerRadius="70%" 
          data={averages}
          width={500}
          height={500}
          // margin={{
          //   top: 5,
          //   right: 30,
          //   left: 20,
          //   bottom: 5,
          // }}
          >
          <PolarGrid />
          <PolarAngleAxis dataKey="subject" />
          <PolarRadiusAxis angle={30} domain={[0, 150]} />
            <Radar name="Playlist" dataKey="A" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
          <Legend />
      </RadarChart> */}
      <AveragesContainer>
        Playlist Averages:
        {averages.map((item, i) => (
          <span key={i + '_averages'}>
            {item.subject}: {item.A}
          </span>
        ))}
      </AveragesContainer>
    </React.Fragment>
  );
};

const LineChartContainer = styled.div`
  width: 900px;
  height: 400px;
`;

const AveragesContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
`;

export default Chart;
