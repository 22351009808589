import { Palette } from 'core';
import * as React from 'react';
import { SVGProps } from 'react';

// Tool used to convert SVG to React component
//https://react-svgr.com/playground/?exportType=named&svgoConfig=%7B%0A%20%20%22plugins%22%3A%20%5B%0A%20%20%5D%0A%7D&typescript=true

const svgData = [
  {
    key: '1A',
    color: '#57f0dc',
    d: 'M431.36 166.77l-84.85 83.64c-8.51-9.31-19.38-16.43-31.66-20.39l30.71-115.17c33.13 9.52 62.59 27.7 85.8 51.92Z',
  },
  {
    key: '2A',
    color: '#7ef2ab',
    d: 'm478.44 250.47-114.88 31.61c-3.01-12-8.97-22.83-17.05-31.67l84.85-83.64c22.16 23.14 38.61 51.79 47.08 83.7Z',
  },
  {
    key: '3A',
    color: '#aff587',
    d: 'M478.44 250.47c4.2 15.82 6.44 32.43 6.44 49.57s-2.27 34.03-6.54 49.96l-115.02-31.09c1.6-6.02 2.45-12.35 2.45-18.87s-.77-12.21-2.21-17.96l114.88-31.61Z',
  },
  {
    key: '4A',
    color: '#e7da9f',
    d: 'M363.32 318.91 478.34 350c-8.94 33.4-26.64 63.23-50.49 86.87l-84.39-84.07c9.42-9.15 16.4-20.8 19.86-33.89Z',
  },
  {
    key: '5A',
    color: '#fcc0a7',
    d: 'm343.46 352.8 84.39 84.07c-23.12 22.92-52 40.02-84.29 48.93L314.3 370.23a73.476 73.476 0 0 0 29.16-17.43Z',
  },
  {
    key: '6A',
    color: '#fdafb7',
    d: 'm314.3 370.23 29.26 115.57c-16.35 4.51-33.57 6.92-51.36 6.92-15.76 0-31.09-1.89-45.75-5.47l29.44-115.45c5.25 1.18 10.7 1.81 16.31 1.81 7.7 0 15.12-1.18 22.1-3.38Z',
  },
  {
    key: '7A',
    color: '#ffaacb',
    d: 'm275.89 371.79-29.44 115.46c-33.51-8.15-63.58-25.07-87.66-48.19l86.86-82.05c8.66 7.08 18.96 12.23 30.24 14.78Z',
  },
  {
    key: '8A',
    color: '#f2abe7',
    d: 'm245.65 357.01-86.86 82.05c-24.77-23.77-43.19-54.09-52.49-88.18l115.34-29.92c4.24 14.33 12.73 26.83 24.01 36.05Z',
  },
  {
    key: '9A',
    color: '#ddb4fb',
    d: 'M221.64 320.96 106.3 350.88c-4.42-16.2-6.78-33.24-6.78-50.84s2.19-33.41 6.31-49.08l114.72 32.26a73.944 73.944 0 0 0-1.93 16.82c0 7.27 1.05 14.29 3.02 20.92Z',
  },
  {
    key: '10A',
    color: '#becdfc',
    d: 'm156.71 163.04 83.94 84.51a73.418 73.418 0 0 0-20.1 35.67l-114.72-32.26c8.88-33.84 26.74-64.05 50.88-87.92Z',
  },
  {
    key: '11A',
    color: '#8be6f9',
    d: 'm244.06 113.42 33.52 114.49c-14.22 2.87-26.96 9.84-36.93 19.64l-83.94-84.51c23.86-23.59 53.84-41 87.35-49.62Z',
  },
  {
    key: '12A',
    color: '#55eff2',
    d: 'm345.56 114.85-30.71 115.17a73.452 73.452 0 0 0-22.65-3.56c-5.01 0-9.89.5-14.62 1.45l-33.52-114.49c15.39-3.96 31.52-6.06 48.14-6.06 18.51 0 36.42 2.61 53.36 7.49Z',
  },
  {
    key: '1B',
    color: '#00EECB',
    d: 'm495.8 103.25-64.44 63.52c-23.21-24.22-52.67-42.4-85.8-51.92l23.32-87.47c48.96 13.74 92.56 40.32 126.92 75.87Z',
  },
  {
    key: '2B',
    color: '#3eed80',
    d: 'm565.7 226.46-87.26 24.01c-8.47-31.91-24.92-60.56-47.08-83.7l64.44-63.52c32.86 33.99 57.29 76.18 69.9 123.21Z',
  },
  {
    key: '3B',
    color: '#86f34c',
    d: 'M565.7 226.46c6.3 23.46 9.66 48.13 9.66 73.58s-3.36 50.11-9.66 73.57L478.34 350c4.27-15.93 6.54-32.68 6.54-49.96s-2.24-33.75-6.44-49.57l87.26-24.01Z',
  },
  {
    key: '4B',
    color: '#deca73',
    d: 'm478.34 350 87.36 23.61c-13.1 48.85-38.94 92.48-73.75 127.12l-64.1-63.86c23.85-23.64 41.55-53.47 50.49-86.87Z',
  },
  {
    key: '5B',
    color: '#fda17b',
    d: 'm427.85 436.87 64.1 63.86c-34.48 34.33-77.77 59.83-126.18 72.81l-22.21-87.74c32.29-8.91 61.17-26.01 84.29-48.93Z',
  },
  {
    key: '6B',
    color: '#ff8995',
    d: 'm343.56 485.8 22.21 87.74c-23.46 6.3-48.12 9.66-73.57 9.66-23.48 0-46.3-2.86-68.12-8.25l22.37-87.7c14.66 3.58 29.99 5.47 45.75 5.47 17.79 0 35.01-2.41 51.36-6.92Z',
  },
  {
    key: '7B',
    color: '#ff80b6',
    d: 'm246.45 487.25-22.37 87.7c-50.36-12.43-95.42-38.35-131.12-73.71l65.83-62.18c24.08 23.12 54.15 40.04 87.66 48.19Z',
  },
  {
    key: '8B',
    color: '#ef82d9',
    d: 'm158.79 439.06-65.83 62.18c-35.07-34.72-61.1-78.54-74.27-127.63l87.61-22.73c9.3 34.09 27.72 64.41 52.49 88.18Z',
  },
  {
    key: '9B',
    color: '#cf8fff',
    d: 'm106.3 350.88-87.61 22.73c-6.3-23.46-9.66-48.12-9.66-73.57s3.36-50.12 9.66-73.58l87.14 24.5c-4.12 15.67-6.31 32.12-6.31 49.08s2.36 34.64 6.78 50.84Z',
  },
  {
    key: '10B',
    color: '#a0b6ff',
    d: 'm92.95 98.84 63.76 64.2c-24.14 23.87-42 54.08-50.88 87.92l-87.14-24.5c13.17-49.08 39.2-92.9 74.26-127.62Z',
  },
  {
    key: '11B',
    color: '#56d9f8',
    d: 'm218.62 26.53 25.44 86.89c-33.51 8.62-63.49 26.03-87.35 49.62l-63.76-64.2c34.4-34.07 77.5-59.39 125.67-72.31Z',
  },
  {
    key: '12B',
    color: '#01EBEC',
    d: 'm368.88 27.38-23.32 87.47c-16.94-4.88-34.85-7.49-53.36-7.49-16.62 0-32.75 2.1-48.14 6.06l-25.44-86.89c23.46-6.3 48.13-9.66 73.58-9.66s52.29 3.66 76.68 10.51Z',
  },
];

export const CamelotSvg = (
  props: SVGProps<SVGSVGElement> & { camelotteKey: string | undefined }
) => {
  const { camelotteKey, ...rest } = props;

  const spawnKey = (pathKey: string | undefined) => pathKey === camelotteKey;

  const filteredData = svgData.find((data) => data.key === camelotteKey);

  const changedArr = [...svgData.filter((svg) => svg.key !== camelotteKey), filteredData];

  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 579.94 596.69" {...rest}>
      <defs>
        <style>
          {
            '.cls-1{letter-spacing:-.04em}.cls-27{font-family:Helvetica-Bold,Helvetica;font-size:24px;font-weight:700}.cls-28{letter-spacing:-.05em}'
          }
        </style>
      </defs>
      {changedArr?.map((props) => (
        <path
          id={`_${props?.key}`}
          key={window.crypto.randomUUID()}
          d={props?.d}
          style={{
            fill: props?.color,
            stroke: Palette.WHITE,
            transition: 'stroke-width 0.5s ease-in-out, opacity 055s ease-in-out',
            strokeWidth: spawnKey(props?.key) ? 6 : 0,
            opacity: spawnKey(props?.key) ? 1 : 0.95,
            strokeLinejoin: 'round',
          }}
        />
      ))}
      {/* <path
        id="_3B"
        d="M565.7 226.46c6.3 23.46 9.66 48.13 9.66 73.58s-3.36 50.11-9.66 73.57L478.34 350c4.27-15.93 6.54-32.68 6.54-49.96s-2.24-33.75-6.44-49.57l87.26-24.01Z"
        style={{
          fill: '#86f34c',
          stroke: Palette.WHITE,
          strokeWidth: spawnKey('3B'),
          transition: 'stroke-width 0.25s ease-in-out',
        }}
      />
      <path
        id="_2B"
        d="m565.7 226.46-87.26 24.01c-8.47-31.91-24.92-60.56-47.08-83.7l64.44-63.52c32.86 33.99 57.29 76.18 69.9 123.21Z"
        style={{
          fill: '#3eed80',
          stroke: Palette.WHITE,
          strokeWidth: spawnKey('2B'),
          transition: 'stroke-width 0.25s ease-in-out',
        }}
      />
      <path
        id="_4B"
        d="m478.34 350 87.36 23.61c-13.1 48.85-38.94 92.48-73.75 127.12l-64.1-63.86c23.85-23.64 41.55-53.47 50.49-86.87Z"
        style={{
          fill: '#deca73',
          stroke: Palette.WHITE,
          strokeWidth: spawnKey('4B'),
          transition: 'stroke-width 0.25s ease-in-out',
        }}
      />
      <path
        id="_1B"
        d="m495.8 103.25-64.44 63.52c-23.21-24.22-52.67-42.4-85.8-51.92l23.32-87.47c48.96 13.74 92.56 40.32 126.92 75.87Z"
        style={{
          fill: '#02edcb',
          stroke: Palette.WHITE,
          strokeWidth: spawnKey('1B'),
          transition: 'stroke-width 0.25s ease-in-out',
        }}
      />
      <path
        id="_5B"
        d="m427.85 436.87 64.1 63.86c-34.48 34.33-77.77 59.83-126.18 72.81l-22.21-87.74c32.29-8.91 61.17-26.01 84.29-48.93Z"
        style={{
          fill: '#fda17b',
          stroke: Palette.WHITE,
          strokeWidth: spawnKey('5B'),
          transition: 'stroke-width 0.25s ease-in-out',
        }}
      />
      <path
        id="_3A"
        d="M478.44 250.47c4.2 15.82 6.44 32.43 6.44 49.57s-2.27 34.03-6.54 49.96l-115.02-31.09c1.6-6.02 2.45-12.35 2.45-18.87s-.77-12.21-2.21-17.96l114.88-31.61Z"
        style={{
          fill: '#aff587',
          stroke: Palette.WHITE,
          strokeWidth: spawnKey('3A'),
          transition: 'stroke-width 0.25s ease-in-out',
        }}
      />
      <path
        id="_2A"
        d="m478.44 250.47-114.88 31.61c-3.01-12-8.97-22.83-17.05-31.67l84.85-83.64c22.16 23.14 38.61 51.79 47.08 83.7Z"
        style={{
          fill: '#7ef2ab',
          stroke: Palette.WHITE,
          strokeWidth: spawnKey('2A'),
          transition: 'stroke-width 0.25s ease-in-out',
        }}
      />
      <path
        id="_4A"
        d="M363.32 318.91 478.34 350c-8.94 33.4-26.64 63.23-50.49 86.87l-84.39-84.07c9.42-9.15 16.4-20.8 19.86-33.89Z"
        style={{
          fill: '#e7da9f',
          stroke: Palette.WHITE,
          strokeWidth: spawnKey('4A'),
          transition: 'stroke-width 0.25s ease-in-out',
        }}
      />
      <path
        id="_1A"
        d="m431.36 166.77-84.85 83.64c-8.51-9.31-19.38-16.43-31.66-20.39l30.71-115.17c33.13 9.52 62.59 27.7 85.8 51.92Z"
        style={{
          fill: '#57f0dc',
          stroke: Palette.WHITE,
          strokeWidth: spawnKey('1A'),
          transition: 'stroke-width 0.25s ease-in-out',
        }}
      />
      <path
        id="_5A"
        d="m343.46 352.8 84.39 84.07c-23.12 22.92-52 40.02-84.29 48.93L314.3 370.23a73.476 73.476 0 0 0 29.16-17.43Z"
        style={{
          fill: '#fcc0a7',
          stroke: Palette.WHITE,
          strokeWidth: spawnKey('5A'),
          transition: 'stroke-width 0.25s ease-in-out',
        }}
      />
      <path
        id="_12B"
        d="m368.88 27.38-23.32 87.47c-16.94-4.88-34.85-7.49-53.36-7.49-16.62 0-32.75 2.1-48.14 6.06l-25.44-86.89c23.46-6.3 48.13-9.66 73.58-9.66s52.29 3.66 76.68 10.51Z"
        style={{
          fill: '#01ebec',
          stroke: Palette.WHITE,
          strokeWidth: spawnKey('12B'),
          transition: 'stroke-width 0.25s ease-in-out',
        }}
      />
      <path
        id="_6B"
        d="m343.56 485.8 22.21 87.74c-23.46 6.3-48.12 9.66-73.57 9.66-23.48 0-46.3-2.86-68.12-8.25l22.37-87.7c14.66 3.58 29.99 5.47 45.75 5.47 17.79 0 35.01-2.41 51.36-6.92Z"
        style={{
          fill: '#ff8995',
          stroke: Palette.WHITE,
          strokeWidth: spawnKey('6B'),
          transition: 'stroke-width 0.25s ease-in-out',
        }}
      />
      <path
        id="center"
        d="M277.58 227.91c4.73-.95 9.61-1.45 14.62-1.45 7.9 0 15.51 1.25 22.65 3.56 12.28 3.96 23.15 11.08 31.66 20.39 8.08 8.84 14.04 19.67 17.05 31.67a73.81 73.81 0 0 1 2.21 17.96 73.4 73.4 0 0 1-2.45 18.87c-3.46 13.09-10.44 24.74-19.86 33.89a73.476 73.476 0 0 1-29.16 17.43c-6.98 2.2-14.4 3.38-22.1 3.38-5.61 0-11.06-.63-16.31-1.81a73.453 73.453 0 0 1-30.24-14.79c-11.28-9.22-19.77-21.72-24.01-36.05a73.329 73.329 0 0 1-3.02-20.92c0-5.79.67-11.42 1.93-16.82a73.418 73.418 0 0 1 20.1-35.67c9.97-9.8 22.71-16.77 36.93-19.64Z"
        style={{
          strokeWidth: 0,
          fill: 'none',
        }}
      />
      <path
        id="_12A"
        d="m345.56 114.85-30.71 115.17a73.452 73.452 0 0 0-22.65-3.56c-5.01 0-9.89.5-14.62 1.45l-33.52-114.49c15.39-3.96 31.52-6.06 48.14-6.06 18.51 0 36.42 2.61 53.36 7.49Z"
        style={{
          fill: '#55eff2',
          stroke: Palette.WHITE,
          strokeWidth: spawnKey('12A'),
          transition: 'stroke-width 0.25s ease-in-out',
        }}
      />
      <path
        id="_6A"
        d="m314.3 370.23 29.26 115.57c-16.35 4.51-33.57 6.92-51.36 6.92-15.76 0-31.09-1.89-45.75-5.47l29.44-115.45c5.25 1.18 10.7 1.81 16.31 1.81 7.7 0 15.12-1.18 22.1-3.38Z"
        style={{
          fill: '#fdafb7',
          stroke: Palette.WHITE,
          strokeWidth: spawnKey('6A'),
          transition: 'stroke-width 0.25s ease-in-out',
        }}
      />
      <path
        id="_11A"
        d="m244.06 113.42 33.52 114.49c-14.22 2.87-26.96 9.84-36.93 19.64l-83.94-84.51c23.86-23.59 53.84-41 87.35-49.62Z"
        style={{
          fill: '#8be6f9',
          stroke: Palette.WHITE,
          strokeWidth: spawnKey('11A'),
          transition: 'stroke-width 0.25s ease-in-out',
        }}
      />
      <path
        id="_7A"
        d="m275.89 371.79-29.44 115.46c-33.51-8.15-63.58-25.07-87.66-48.19l86.86-82.05c8.66 7.08 18.96 12.23 30.24 14.78Z"
        style={{
          fill: '#ffaacb',
          stroke: Palette.WHITE,
          strokeWidth: spawnKey('7A'),
          transition: 'stroke-width 0.25s ease-in-out',
        }}
      />
      <path
        id="_7B"
        d="m246.45 487.25-22.37 87.7c-50.36-12.43-95.42-38.35-131.12-73.71l65.83-62.18c24.08 23.12 54.15 40.04 87.66 48.19Z"
        style={{
          fill: '#ff80b6',
          stroke: Palette.WHITE,
          strokeWidth: spawnKey('7B'),
          transition: 'stroke-width 0.25s ease-in-out',
        }}
      />
      <path
        id="_8A"
        d="m245.65 357.01-86.86 82.05c-24.77-23.77-43.19-54.09-52.49-88.18l115.34-29.92c4.24 14.33 12.73 26.83 24.01 36.05Z"
        style={{
          fill: '#f2abe7',
          stroke: Palette.WHITE,
          strokeWidth: spawnKey('8A'),
          transition: 'stroke-width 0.25s ease-in-out',
        }}
      />
      <path
        id="_11B"
        d="m218.62 26.53 25.44 86.89c-33.51 8.62-63.49 26.03-87.35 49.62l-63.76-64.2c34.4-34.07 77.5-59.39 125.67-72.31Z"
        style={{
          fill: '#56d9f8',
          stroke: Palette.WHITE,
          strokeWidth: spawnKey('11B'),
          transition: 'stroke-width 0.25s ease-in-out',
        }}
      />
      <path
        id="_10A"
        d="m156.71 163.04 83.94 84.51a73.418 73.418 0 0 0-20.1 35.67l-114.72-32.26c8.88-33.84 26.74-64.05 50.88-87.92Z"
        style={{
          fill: '#becdfc',
          stroke: Palette.WHITE,
          strokeWidth: spawnKey('10A'),
          transition: 'stroke-width 0.25s ease-in-out',
        }}
      />
      <path
        id="_9A"
        d="M221.64 320.96 106.3 350.88c-4.42-16.2-6.78-33.24-6.78-50.84s2.19-33.41 6.31-49.08l114.72 32.26a73.944 73.944 0 0 0-1.93 16.82c0 7.27 1.05 14.29 3.02 20.92Z"
        style={{
          fill: '#ddb4fb',
          stroke: Palette.WHITE,
          strokeWidth: spawnKey('9A'),
          transition: 'stroke-width 0.25s ease-in-out',
        }}
      />
      <path
        id="_8B"
        d="m158.79 439.06-65.83 62.18c-35.07-34.72-61.1-78.54-74.27-127.63l87.61-22.73c9.3 34.09 27.72 64.41 52.49 88.18Z"
        style={{
          fill: '#ef82d9',
          stroke: Palette.WHITE,
          strokeWidth: spawnKey('8B'),
          transition: 'stroke-width 0.25s ease-in-out',
        }}
      />
      <path
        id="_10B"
        d="m92.95 98.84 63.76 64.2c-24.14 23.87-42 54.08-50.88 87.92l-87.14-24.5c13.17-49.08 39.2-92.9 74.26-127.62Z"
        style={{
          fill: '#a0b6ff',
          stroke: Palette.WHITE,
          strokeWidth: spawnKey('10B'),
          transition: 'stroke-width 0.25s ease-in-out',
        }}
      />
      <path
        id="_9B"
        d="m106.3 350.88-87.61 22.73c-6.3-23.46-9.66-48.12-9.66-73.57s3.36-50.12 9.66-73.58l87.14 24.5c-4.12 15.67-6.31 32.12-6.31 49.08s2.36 34.64 6.78 50.84Z"
        style={{
          fill: '#cf8fff',
          stroke: Palette.WHITE,
          strokeWidth: spawnKey('9B'),
          transition: 'stroke-width 0.25s ease-in-out',
        }}
      /> */}
      <text className="cls-27" transform="translate(279.6 169.47)">
        <tspan x={0} y={0}>
          {'12'}
        </tspan>
        <tspan x={26.7} y={0} className="cls-1">
          {'A'}
        </tspan>
      </text>
      <text className="cls-27" transform="translate(272.99 68.59)">
        <tspan x={0} y={0}>
          {'12B'}
        </tspan>
      </text>
      <text className="cls-27" transform="translate(395.56 94.45)">
        <tspan x={0} y={0}>
          {'1B'}
        </tspan>
      </text>
      <text className="cls-27" transform="translate(405.78 232.89)">
        <tspan x={0} y={0}>
          {'2'}
        </tspan>
        <tspan x={13.35} y={0} className="cls-1">
          {'A'}
        </tspan>
      </text>
      <text className="cls-27" transform="translate(419.6 306.46)">
        <tspan x={0} y={0}>
          {'3'}
        </tspan>
        <tspan x={13.35} y={0} className="cls-1">
          {'A'}
        </tspan>
      </text>
      <text className="cls-27" transform="translate(405.78 380.8)">
        <tspan x={0} y={0}>
          {'4'}
        </tspan>
        <tspan x={13.35} y={0} className="cls-1">
          {'A'}
        </tspan>
      </text>
      <text className="cls-27" transform="translate(353.98 428.56)">
        <tspan x={0} y={0}>
          {'5'}
        </tspan>
        <tspan x={13.35} y={0} className="cls-1">
          {'A'}
        </tspan>
      </text>
      <text className="cls-27" transform="translate(281.82 445.49)">
        <tspan x={0} y={0}>
          {'6'}
        </tspan>
        <tspan x={13.35} y={0} className="cls-1">
          {'A'}
        </tspan>
      </text>
      <text className="cls-27" transform="translate(211.48 432.49)">
        <tspan x={0} y={0}>
          {'7'}
        </tspan>
        <tspan x={13.35} y={0} className="cls-1">
          {'A'}
        </tspan>
      </text>
      <text className="cls-27" transform="translate(158.34 376.66)">
        <tspan x={0} y={0}>
          {'8'}
        </tspan>
        <tspan x={13.35} y={0} className="cls-1">
          {'A'}
        </tspan>
      </text>
      <text className="cls-27" transform="translate(141.81 307.49)">
        <tspan x={0} y={0}>
          {'9'}
        </tspan>
        <tspan x={13.35} y={0} className="cls-1">
          {'A'}
        </tspan>
      </text>
      <text className="cls-27" transform="translate(151.66 236.45)">
        <tspan x={0} y={0}>
          {'10'}
        </tspan>
        <tspan x={26.7} y={0} className="cls-1">
          {'A'}
        </tspan>
      </text>
      <text className="cls-27" transform="translate(205.46 189.06)">
        <tspan x={0} y={0} className="cls-28">
          {'1'}
        </tspan>
        <tspan x={12.04} y={0}>
          {'1'}
        </tspan>
        <tspan x={25.38} y={0} className="cls-1">
          {'A'}
        </tspan>
      </text>
      <text className="cls-27" transform="translate(348.42 187.69)">
        <tspan x={0} y={0}>
          {'1'}
        </tspan>
        <tspan x={13.35} y={0} className="cls-1">
          {'A'}
        </tspan>
      </text>
      <text className="cls-27" transform="translate(494.25 194.12)">
        <tspan x={0} y={0}>
          {'2B'}
        </tspan>
      </text>
      <text className="cls-27" transform="translate(511.51 313.78)">
        <tspan x={0} y={0}>
          {'3B'}
        </tspan>
      </text>
      <text className="cls-27" transform="translate(487.91 425.63)">
        <tspan x={0} y={0}>
          {'4B'}
        </tspan>
      </text>
      <text className="cls-27" transform="translate(397.13 518.48)">
        <tspan x={0} y={0}>
          {'5B'}
        </tspan>
      </text>
      <text className="cls-27" transform="translate(285.84 551.74)">
        <tspan x={0} y={0}>
          {'6B'}
        </tspan>
      </text>
      <text className="cls-27" transform="translate(165.62 518.48)">
        <tspan x={0} y={0}>
          {'7B'}
        </tspan>
      </text>
      <text className="cls-27" transform="translate(77.62 432.06)">
        <tspan x={0} y={0}>
          {'8B'}
        </tspan>
      </text>
      <text className="cls-27" transform="translate(42.32 309.35)">
        <tspan x={0} y={0}>
          {'9B'}
        </tspan>
      </text>
      <text className="cls-27" transform="translate(59.45 194.12)">
        <tspan x={0} y={0}>
          {'10B'}
        </tspan>
      </text>
      <text className="cls-27" transform="translate(155.24 98.64)">
        <tspan x={0} y={0} className="cls-28">
          {'1'}
        </tspan>
        <tspan x={12.04} y={0}>
          {'1B'}
        </tspan>
      </text>
    </svg>
  );
};
