import React from "react";
// import { CDN_BASE_URI, FETCH_TIME_DURATION } from 'core/utilities';

export const fetchButtonFocus = (
  ref: React.MutableRefObject<HTMLDivElement | null>,
  dataId: string,
  dataValue: string | number,
) => {
  return ref.current
    ? ref.current.querySelectorAll(`[data-${dataId}="${dataValue}"]`)[0]
    : null;
};

export const downloadBlob = (blobUrl: string, filename: string) => {
  let a = document.createElement("a");
  a.download = filename;
  a.href = blobUrl;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(a.href);
  a.remove();
  return;
};

export const isArrayDirty = (a: Array<any>, b: Array<any>) => a.length === b.length &&
  a.every((element, index) => element.trackId === b[index].trackId);

// const downloadTime = (currentChunk: number, amountOfChunks: number, startTime: number) => {
//   const elapsedTime = (new Date().getTime()) - startTime;
//   const chunksPerTime = currentChunk / elapsedTime;
//   const estimatedTotalTime = amountOfChunks / chunksPerTime;
//   const timeLeftInSeconds = (estimatedTotalTime - elapsedTime) / 1000;
//   // var withOneDecimalPlace = Math.round(timeLeftInSeconds * 10) / 10;
//   return timeLeftInSeconds;
// };

// const watchProgress = async (
//   response: Response,
//   progressCallback: (percentage: number) => void,
//   getDownloadTime?: (time: number, receivedLength: string, contentLength: string) => void

// ) => {
//   if (!response.body || !response.headers) return;
//   const reader = response.body.getReader();

//   const startTime = new Date().getTime();
//   const contentLength = Number(response.headers.get('Content-Length')) || 0;

//   let receivedLength = 0;

//   // Process the data
//   const processChunk = async (value: Uint8Array | undefined) => {
//     // Add to the received length
//     receivedLength += value?.length || 0;
//     const percentage = Math.round((receivedLength / contentLength) * 100);
//     progressCallback(percentage);
//     if (getDownloadTime) {
//       const time = downloadTime(receivedLength, contentLength, startTime);
//       const receivedLengthBytes = niceBytes(receivedLength.toString());
//       const contentLengthBytes = niceBytes(contentLength.toString());
//       getDownloadTime(time, receivedLengthBytes, contentLengthBytes);
//     }
//   }

//   const read = async () => {
//     let data;
//     try {
//       data = await reader.read();
//     } catch {
//       // Ignore errors because we can only handle the main response
//       return;
//     };

//     // Continue reading data until done
//     if (!data.done) {
//       processChunk(data.value);
//       await read();
//     };
//   };

//   read();
// };

// export const downloadResource = async (
//   url: string,
//   fileName?: string,
//   abortController?: AbortController,
//   progressCallback?: (progress: number) => void,
//   getDownloadTime?: (time: number, receivedLength: string, contentLength: string) => void,
//   timeout: number = FETCH_TIME_DURATION,
// ) => {
//   if (!url) return;
//   if (url.length === 0) return;

//   const filename = fileName ? fileName : url.split('\\').pop()?.split('/').pop();

//   const audioFileName = filename || 'callasto music';

//   const signal = abortController?.signal;

//   const timeoutMessage = `Download cancelled: timed out at ${timeout / 1000} seconds. Check your network connection`;
//   let id = setTimeout(() => abortController?.abort(timeoutMessage), timeout);

//   try {
//     const response = await fetch(url, { signal });

//     clearTimeout(id);

//     if (!response.ok) {
//       throw new Error(`Failed to fetch ${url}: ${response.status} (${response.statusText})`)
//     }

//     // Read the data to track progress
//     if (progressCallback) {
//       await watchProgress(response.clone(), progressCallback, getDownloadTime);
//     }

//     const contentType = response.headers.get('Content-Type') ?? 'audio/mp3';
//     const blob = await response.blob();
//     const newBlob = new Blob([blob], { type: contentType });

//     let blobUrl = window.URL.createObjectURL(newBlob);
//     return downloadBlob(blobUrl, audioFileName);
//   }
//   catch (error) {
//     //@ts-ignore
//     if (signal?.aborted && signal.reason) {
//       //@ts-ignore
//       throw new Error(signal.reason);
//     } else throw error;
//   } finally {
//     if (id) {
//       clearTimeout(id);
//     }
//   };
// }

export const isEmpty = (obj: { [key: string]: any }) =>
  Object.keys(obj).length === 0;

export const getFileExt = (path: string) => path.split(".").pop();

export const isValidEmail = (value: string): boolean => {
  const trimVal = value.trim();
  const validLength = trimVal.length >= 4 && trimVal.length <= 254;
  const regex = new RegExp(/\S+@\S+\.\S+/);

  return !!trimVal && validLength && regex.test(trimVal);
};

export const isValidUsername = (value: string): boolean => {
  const trimVal = value.trim();
  const validLength = trimVal.length >= 1 && trimVal.length <= 254;
  const regex = new RegExp(/^[a-zA-Z0-9_.-]+$/);

  return !!trimVal && validLength && regex.test(trimVal);
};

// export const formatArtworkSrc = (src: string, fallback?: boolean, dest = 'artwork', svg?: boolean,) =>
//   svg
//     ? `${CDN_BASE_URI}/${dest}/${src}.svg`
//     : fallback
//       ? `${CDN_BASE_URI}/${dest}/${src}.jpeg`
//       : `${CDN_BASE_URI}/${dest}/${src}.webp`;

export const validateTimeRange = (date: number, ms: number) => {
  const range = Date.now() - ms;
  return date > range;
};

export const niceBytes = (val: string) => {
  const units = ["bytes", "kb", "mb", "gb", "TiB", "PiB", "EiB", "ZiB", "YiB"];

  let l = 0, n = parseInt(val, 10) || 0;

  while (n >= 1024 && ++l) {
    n = n / 1024;
  }

  return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + units[l]);
};

export const clampInt = (num: number, min: number = 0, max: number = 100) =>
  Math.min(Math.max(num, min), max);

export const convertToCSV = async (arr: Array<any>) => {
  const array = [Object.keys(arr[0])].concat(arr);

  return await array.map((val) => {
    return Object.values(val).toString();
  }).join("\n");
};

export const convertMs = (ms: number) => {
  const minutes: number = Math.floor(ms / 60000);
  const seconds: number = Number(((ms % 60000) / 1000).toFixed(0));

  return (
    seconds === 60
      ? (minutes + 1) + ":00"
      : minutes + ":" + (seconds < 10 ? "0" : "") + seconds
  );
};

export const isAlphaNumeral = (value: string) => {
  const validLength = value.length >= 1 && value.length <= 254;
  const regex = new RegExp(/^[a-zA-Z0-9]+$/);

  return !!value && validLength && regex.test(value);
};

export const average = (array: Array<number>) =>
  Math.round(array.reduce((a: any, b: any) => a + b, 0) / array.length);

export const formatPrice = (currency: string, unit_amount?: number ) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency!,
      minimumFractionDigits: 0,
    }).format((unit_amount || 0) / 100);

export const isObject = (value: any) => {
  const type = typeof value;
  return value != null && (type === 'object' || type === 'function');
};

export const debounce = (func: () => void, wait: number, options: any) => {
  let lastArgs: any;
  let lastThis: any;
  let maxWait: any;
  let result: any;
  let timerId: any;
  let lastCallTime: any;
  let lastInvokeTime = 0;
  let leading = false;
  let maxing = false;
  let trailing = true;

  // Bypass `requestAnimationFrame` by explicitly setting `wait=0`.
  const useRAF = !wait && wait !== 0 && typeof requestAnimationFrame === 'function';

  if (typeof func !== 'function') {
      throw new TypeError('Expected a function');
  }
  wait = +wait || 0;
  if (isObject(options)) {
      leading = !!options.leading;
      maxing = 'maxWait' in options;
      maxWait = maxing ? Math.max(+options.maxWait || 0, wait) : maxWait;
      trailing = 'trailing' in options ? !!options.trailing : trailing;
  }

  function invokeFunc(time: number) {
      const args = lastArgs;
      const thisArg = lastThis;

      lastArgs = lastThis = undefined;
      lastInvokeTime = time;
      result = func.apply(thisArg, args);
      return result;
  }

  function startTimer(pendingFunc: () => void, milliseconds: number) {
      if (useRAF) {
          cancelAnimationFrame(timerId);
          return requestAnimationFrame(pendingFunc);
      }
      // eslint-disable-next-line @typescript-eslint/no-implied-eval
      return setTimeout(pendingFunc, milliseconds);
  }

  function cancelTimer(id: number) {
      if (useRAF) {
          cancelAnimationFrame(id);
          return;
      }
      clearTimeout(id);
  }

  function leadingEdge(time: number) {
      // Reset any `maxWait` timer.
      lastInvokeTime = time;
      // Start the timer for the trailing edge.
      timerId = startTimer(timerExpired, wait);
      // Invoke the leading edge.
      return leading ? invokeFunc(time) : result;
  }

  function remainingWait(time: number) {
      const timeSinceLastCall = time - lastCallTime;
      const timeSinceLastInvoke = time - lastInvokeTime;
      const timeWaiting = wait - timeSinceLastCall;

      return maxing ? Math.min(timeWaiting, maxWait - timeSinceLastInvoke) : timeWaiting;
  }

  function shouldInvoke(time: number) {
      const timeSinceLastCall = time - lastCallTime;
      const timeSinceLastInvoke = time - lastInvokeTime;

      // Either this is the first call, activity has stopped and we're at the
      // trailing edge, the system time has gone backwards and we're treating
      // it as the trailing edge, or we've hit the `maxWait` limit.
      return (
          lastCallTime === undefined ||
          timeSinceLastCall >= wait ||
          timeSinceLastCall < 0 ||
          (maxing && timeSinceLastInvoke >= maxWait)
      );
  }

  function timerExpired() {
      const time = Date.now();
      if (shouldInvoke(time)) {
          return trailingEdge(time);
      }
      // Restart the timer.
      timerId = startTimer(timerExpired, remainingWait(time));
      return undefined;
  }

  function trailingEdge(time: number) {
      timerId = undefined;

      // Only invoke if we have `lastArgs` which means `func` has been
      // debounced at least once.
      if (trailing && lastArgs) {
          return invokeFunc(time);
      }
      lastArgs = lastThis = undefined;
      return result;
  }

  function cancel() {
      if (timerId !== undefined) {
          cancelTimer(timerId);
      }
      lastInvokeTime = 0;
      lastArgs = lastCallTime = lastThis = timerId = undefined;
  }

  function flush() {
      return timerId === undefined ? result : trailingEdge(Date.now());
  }

  function pending() {
      return timerId !== undefined;
  }

  function debounced(...args: any) {
      const time = Date.now();
      const isInvoking = shouldInvoke(time);

      lastArgs = args;
      // @ts-ignore
      lastThis = this;
      lastCallTime = time;

      if (isInvoking) {
          if (timerId === undefined) {
              return leadingEdge(lastCallTime);
          }
          if (maxing) {
              // Handle invocations in a tight loop.
              timerId = startTimer(timerExpired, wait);
              return invokeFunc(lastCallTime);
          }
      }
      if (timerId === undefined) {
          timerId = startTimer(timerExpired, wait);
      }
      return result;
  }
  debounced.cancel = cancel;
  debounced.flush = flush;
  debounced.pending = pending;
  return debounced;
};
