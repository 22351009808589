import React, { useCallback, useEffect, useRef, useMemo, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { ToastContext } from './ToastContext';
import { ReactPortal } from '../ReactPortal';
import { ToastMessageType } from './Toast';
import { DeviceSize } from 'core/config';
import Toast from './Toast';
// import { MediaQuery } from 'core';

type PositionType =
  | 'center-bottom'
  | 'center-top'
  | 'top-left'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-right';

type ToastListType = {
  children: React.ReactChild;
};

const TOAST_FAD_OUT_DELAY = 450;
const TOAST_DELAY = 4000;

const ToastProvider: React.FunctionComponent<ToastListType> = (props): any => {
  const timers = useRef(new Map());
  const listRef = useRef(null);

  const [toasts, setToasts] = useState<
    Array<{ id: string; message: string; type: ToastMessageType; autoclose: boolean }>
  >([]);

  const isMobile = window.innerWidth < DeviceSize.tablet;
  const [position, setPosition] = useState<PositionType>(isMobile ? 'center-bottom' : 'center-top');

  useEffect(() => {
    const newToasts = toasts.filter((toast) => !timers.current.has(toast.id));

    newToasts.forEach((newToast) => {
      timers.current.set(
        newToast.id,
        setTimeout(() => {
          setToasts((currToasts) => currToasts.filter((toast) => toast.id !== newToast.id));
          timers.current.delete(newToast.id);
        }, TOAST_DELAY)
      );
    });
  }, [toasts]);

  useEffect(() => {
    let localTimerRef: any = null;

    if (timers.current) localTimerRef = timers.current;

    return () => {
      [...localTimerRef.values()].forEach((timer) => clearTimeout(timer));
    };
  }, []);

  const showToast = (message: string, type: ToastMessageType, autoclose: boolean = false) => {
    const toast = {
      id: window.crypto.randomUUID(),
      autoclose,
      message,
      type,
    };

    setToasts((prevToasts) => [...prevToasts, toast]);
  };

  const removeToast = (id: string) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  const handleScrolling = useCallback(
    (el: any) => {
      const isTopPosition = ['top-left', 'top-right'].includes(position);
      if (isTopPosition) {
        el?.scrollTo(0, el.scrollHeight);
      } else {
        el?.scrollTo(0, 0);
      }
    },
    [position]
  );

  useEffect(() => {
    handleScrolling(listRef.current);
  }, [position, toasts, handleScrolling]);

  const sortedToasts = position.includes('bottom') ? [...toasts].reverse() : [...toasts];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const contextValue = useMemo(() => ({ showToast, removeToast, setPosition }), []);

  return (
    <ToastContext.Provider value={contextValue}>
      {props.children}
      <ReactPortal>
        <ToastList $position={position} aria-live="assertive" ref={listRef}>
          {sortedToasts.map((toast) => (
            <Toast
              key={`toast-key__${toast.id}`}
              message={toast.message}
              type={toast.type}
              autoClose={true}
              onClose={() => removeToast(toast.id)}
            />
          ))}
        </ToastList>
      </ReactPortal>
    </ToastContext.Provider>
  );
};

const ToastList = styled.div<{ $position: PositionType }>`
  position: fixed;
  padding: 1em;
  ${({ $position: position }) =>
    position.includes('bottom') ? 'padding-bottom: 5vh' : 'padding-top: 5vh'};
  width: 100%;
  max-width: 275px;
  max-height: 100vh;
  overflow: hidden auto;
  z-index: 200; // 100?

  ${({ $position: position }) =>
    position === 'center-top'
      ? css`
          top: 0;
          left: 50%;
          transform: translate(-50%, 0);
          > div {
            animation: ${fadeInTop} 250ms forwards, ${fadeOutTop} 750ms forwards;
            animation-delay: 0ms, ${TOAST_DELAY - TOAST_FAD_OUT_DELAY}ms;
          }
        `
      : position === 'center-bottom'
      ? css`
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 0);
          > div {
            animation: ${fadeInBottom} 250ms forwards, ${fadeOutBottom} 750ms forwards;
            animation-delay: 0ms, ${TOAST_DELAY - TOAST_FAD_OUT_DELAY}ms;
          }
        `
      : position === 'top-left'
      ? css`
          top: 0;
          left: 0;
          > div {
            animation: ${toastInLeft} 250ms forwards, ${fadeOutTop} 750ms forwards;
            animation-delay: 0ms, ${TOAST_DELAY - TOAST_FAD_OUT_DELAY}ms;
          }
        `
      : position === 'top-right'
      ? css`
          top: 0;
          right: 0;
          > div {
            animation: ${toastInRight} 250ms forwards, ${fadeOutTop} 750ms forwards;
            animation-delay: 0ms, ${TOAST_DELAY - TOAST_FAD_OUT_DELAY}ms;
          }
        `
      : position === 'bottom-left'
      ? css`
          bottom: 0;
          left: 0;
          > div {
            animation: ${toastInLeft} 250ms forwards, ${fadeOutBottom} 750ms forwards;
            animation-delay: 0ms, ${TOAST_DELAY - TOAST_FAD_OUT_DELAY}ms;
          }
        `
      : css`
          bottom: 0;
          right: 0;
          > div {
            animation: ${toastInRight} 250ms forwards, ${fadeOutBottom} 750ms forwards;
            animation-delay: 0ms, ${TOAST_DELAY - TOAST_FAD_OUT_DELAY}ms;
          }
        `}

  ::-webkit-scrollbar {
    width: 0.35em;
  }

  ::-webkit-scrollbar-track {
    background-color: hsl(25 10% 75%);
    border-radius: 0.35em;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0.35em;
    background-color: hsl(25 10% 65%);
  }

  ::-webkit-scrollbar-thumb:window-inactive {
    background-color: hsl(25 10% 50%);
  }
`;

const fadeOutTop = keyframes`
  from {opacity: 1; transform: scale(1) translateY(0);}
  to {opacity: 0; transform: scale(0.25) translateY(-200%);}
`;

const fadeInTop = keyframes`
  from {opacity: 0; transform: scale(0.25) translateY(-200%);}
  to {opacity: 1; transform: scale(1) translateY(0);}
`;

const fadeOutBottom = keyframes`
  from {opacity: 1; transform: scale(1) translateY(0);}
  to {opacity: 0; transform: scale(0.25) translateY(200%);}
`;

const fadeInBottom = keyframes`
  from {opacity: 0; transform: scale(0.25) translateY(-200%);}
  to {opacity: 1; transform: scale(1) translateY(0);}
`;

const toastInRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const toastInLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export default ToastProvider;
