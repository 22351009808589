import React from 'react';
import styled, { css } from 'styled-components';

import { Palette } from 'core/config';

interface Props {
  [key: string]: any;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.KeyboardEvent<HTMLInputElement>
  ) => void;
  onBlur: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  placeholder: string;
  disabled: boolean;
  touched: boolean;
  value: string;
  error: string;
  name: string;

  label?: string;
  textArea?: boolean;
  required?: boolean;
  helperText?: string;
  minLength?: number;
  maxLength?: number;
}

export const TextInput: React.FunctionComponent<Props> = ({
  onChange,
  onBlur,
  maxLength = 256,
  minLength = 2,
  placeholder,
  helperText,
  required,
  disabled,
  textArea,
  touched,
  label,
  value,
  error,
  name,
  ...rest
}) => {
  const inputID = `${name}__input`;

  if (textArea)
    return (
      <InputContainer>
        {label ? (
          <Label $margin $required={required} htmlFor={inputID}>
            {label}
          </Label>
        ) : null}
        <MessageInput
          id={inputID}
          placeholder={placeholder}
          name={name}
          value={value}
          minLength={minLength}
          maxLength={maxLength}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          required={required}
          {...rest}
        />
        {helperText ? <HelperText $marginLeft>{helperText}</HelperText> : null}
        <ErrorMessage aria-live="polite" $marginLeft $active={touched && !!error}>
          {touched && error ? error : undefined}
        </ErrorMessage>
      </InputContainer>
    );

  return (
    <InputContainer>
      {label ? (
        <Label htmlFor={inputID} $margin $required>
          {label}
        </Label>
      ) : null}
      <TextInputField
        id={inputID}
        placeholder={placeholder}
        name={name}
        value={value}
        minLength={minLength}
        maxLength={maxLength}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        required={required}
        {...rest}
      />
      {helperText ? <HelperText $marginLeft>{helperText}</HelperText> : null}
      <ErrorMessage aria-live="polite" $marginLeft $active={touched && !!error}>
        {touched && error ? error : undefined}
      </ErrorMessage>
    </InputContainer>
  );
};

export const Label = styled.label<{
  $margin?: boolean;
  $required?: boolean;
  $requiredAbsolute?: boolean;
  $marginAuto?: boolean;
  $marginLeft?: boolean;
}>`
  position: relative;
  text-align: start;
  color: ${Palette.WHITE};
  font-size: 16px;
  ${({ $margin }) => ($margin ? 'margin-bottom: 7px;' : '')}
  ${({ $marginAuto }) => ($marginAuto ? 'margin: auto; padding-bottom: 10px;' : '')}

  ${({ $required, $requiredAbsolute }) =>
    $required
      ? css`
          &:before {
            content: '* ';
            color: ${Palette.RED};

            ${$requiredAbsolute
              ? css`
                  position: absolute;
                  left: -10px;
                `
              : ''}
          }
        `
      : null}
`;

export const TextInputField = styled.input`
  border: 2px solid ${Palette.SPOTIFY_GREEN};
  border-radius: 5px;
  padding: 5px;
  outline-color: ${Palette.SPOTIFY_GREEN};
  font-size: 16px;
  &:disabled {
    background-color: transparent;
    color: ${Palette.WHITE};
  }
`;

export const MessageInput = styled.textarea`
  border: 2px solid ${Palette.RED};
  border-radius: 5px;
  padding: 5px;
  outline-color: ${Palette.BLUE};
  font-size: 16px;
  min-height: 150px;
  max-width: 100%;
  min-width: 100%;
  &:disabled {
    background-color: transparent;
    color: ${Palette.WHITE};
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 88px;
  margin-top: 7px;
`;

export const HelperText = styled.span<{ $marginLeft?: boolean }>`
  color: ${Palette.WHITE};
  font-size: 9px;
  margin-top: 5px;
  text-align: start;
  ${({ $marginLeft }) => ($marginLeft ? 'margin-left: 5px' : '')};
`;

export const ErrorMessage = styled(HelperText)<{ $active: boolean }>`
  height: 10px;
  color: ${Palette.RED};
  opacity: ${({ $active }) => ($active ? 1 : 0)};
  transition: opacity 0.3s ease-in-out 0.1s;
  margin-bottom: 5px;
  text-align: start;
`;
